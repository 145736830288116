import { useEffect, useState } from 'react'
import client from '../../../API'
import uploadToVimeo, { getVideoDetails } from '../../../utils/uploadToVimeo'
import uploadToCloudinary from '../../../utils/uploadToCloudinary'
import { FileDropzoneFile } from '../../../components/FileDropzone'
import useCreateHostMediaMutation from '../mutations/useCreateHostMediaMutation'
import { MediaTypeEnum, MediaHostEnum, MediaStatusEnum } from '../../../types'
import { VIMEO_TRANSCODE_STATUS_MAP, MEDIA_REGEX_MAP } from '../constants/media'
import getHostMediaByHostId from '../queries/getHostMediaByHostId'
import getCloudinaryGetAssetById from '../queries/getCloudinaryGetAssetById'
import useTranslation from '../../../hooks/useTranslation'
import { formatBytesToMB, formatStringWithArgs } from '../../../utils/format'
import { MediaUploaderValue } from './MediaUploader'

type MediaUploadError = 'createHostMediaFailed' | 'noFileSelected'

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

export default function useMediaUploader(
  setValue: (value: Partial<MediaUploaderValue> | null) => void,
  sizeLimit: number | null = null,
  audioFileSizeLimit: number | null = null,
  uploading?: [boolean, (value: boolean) => void],
  heredatedProgress?: [Number | null, (value: Number | null) => void]
) {
  const { t } = useTranslation()
  const [lProgress, lSetProgress] = useState<Number | null>(null)
  const [progress, setProgress] = heredatedProgress || [lProgress, lSetProgress]
  const [isUploading, setIsUploading] = uploading || [() => {}, () => {}]
  const [error, setError] = useState<MediaUploadError | string | null>(null)
  const createHostMedia = useCreateHostMediaMutation()
  const [data, setData] = useState(null)

  useEffect(() => {
    if (data && isUploading) {
      setIsUploading(false)
      setValue(data)
    }
  }, [data])

  const uploadMedia = async (file: FileDropzoneFile) => {
    let isUploadingInternal = false

    if (!file) {
      setError('noFileSelected')
      setIsUploading(false)
      setProgress(null)
      return
    }

    const isVideo = file.type.indexOf('video/') === 0

    const isAudio = !isVideo && file.type.indexOf('audio/') === 0
    if (
      isAudio &&
      audioFileSizeLimit !== null &&
      file.size &&
      file.size > audioFileSizeLimit
    ) {
      setError(
        formatStringWithArgs(
          t('forms:exceedFileSize'),
          formatBytesToMB(audioFileSizeLimit)
        )
      )
      setIsUploading(false)
      setProgress(null)
      return
    }

    if (
      !isVideo &&
      !isAudio &&
      sizeLimit !== null &&
      file.size &&
      file.size > sizeLimit
    ) {
      setError(
        formatStringWithArgs(
          t('forms:exceedFileSize'),
          formatBytesToMB(sizeLimit)
        )
      )
      setIsUploading(false)
      setProgress(null)
      return
    }

    setError(null)
    setIsUploading(true)
    setProgress(null)

    if (setValue) {
      setValue({
        name: file.name,
        type: isVideo ? 'video' : 'audio',
        status: 'uploading'
      })
    }

    let hostMedia
    try {
      setIsUploading(true)
      isUploadingInternal = true
      if (isVideo) {
        hostMedia = hostMediaFromVimeoResponse(
          await uploadToVimeo(file, setProgress)
        )
        while (hostMedia.status !== MediaStatusEnum.Complete) {
          await delay(500)
          if (!isUploadingInternal) return
          hostMedia = hostMediaFromVimeoResponse(
            await getVideoDetails(hostMedia.hostId)
          )
        }
      } else {
        hostMedia = hostMediaFromCloudinaryResponse(
          await uploadToCloudinary(file, 'mediaAudio', setProgress)
        )
      }
    } catch (error) {
      if (setError) {
        if (error instanceof Error) {
          setError(error.message)
        } else {
          setError(String(error))
        }
      }
      setIsUploading(false)
      isUploadingInternal = false
      setValue(null)
      setProgress(null)
    }

    try {
      const result = await createHostMedia({ variables: hostMedia })
      hostMedia = { ...hostMedia, ...result.data.createHostMedia }
    } catch {
      setError('createHostMediaFailed')
    }

    setProgress(null)

    if (!isUploadingInternal) return

    if (isVideo) {
      setData({ ...hostMedia, status: 'processing' })
    } else {
      setData({ ...hostMedia, status: 'complete' })
    }
  }

  const validateUploadedMediaUrl = async (
    url: string,
    throwsOnError = false
  ): Promise<[boolean, any]> => {
    try {
      let mediaData: any = await validateVimeoUrl(url)

      if (throwsOnError && mediaData?.media?.error) {
        throw new Error(mediaData?.media?.error)
      }

      if (!mediaData) {
        mediaData = await validateCloudinaryUrl(url)
      }

      if (throwsOnError && mediaData?.media?.error) {
        throw new Error(mediaData?.media?.error)
      }

      if (mediaData && mediaData?.hostId && mediaData?.media) {
        const result = await client.query({
          query: getHostMediaByHostId,
          variables: { hostId: mediaData.hostId }
        })

        const hostMedia = result?.data?.hostMediaByHostId
        // If the host media exist in our database use it
        if (hostMedia) {
          setValue({ ...hostMedia })

          return [true, hostMedia]
        } else if (mediaData.media) {
          // If the host media doesn't exist in our db, pull it in if the media is valid
          try {
            const hostMedia =
              mediaData.host === MediaHostEnum.Cloudinary
                ? hostMediaFromCloudinaryResponse(mediaData.media)
                : hostMediaFromVimeoResponse(mediaData.media)

            const result = await createHostMedia({ variables: hostMedia })
            setValue({ ...result.data.createHostMedia })

            return [true, result.data.createHostMedia]
          } catch {
            return [false, null]
          }
        }
      }
      return [false, null]
    } catch (validateError) {
      return [false, validateError]
    }
  }

  return { uploadMedia, progress, error, validateUploadedMediaUrl }
}

/**
 * Validate url against Cloudinary
 * Attempt to parse hostId from url
 * Determine if the media exists in Cloudinary
 * @param {String} url
 */
export const validateCloudinaryUrl = async (url: string) => {
  try {
    const hostId = url.match(MEDIA_REGEX_MAP.cloudinaryHostId)?.[1]
    if (!hostId) return null

    const result = await client.query({
      query: getCloudinaryGetAssetById,
      variables: {
        id: decodeURI(hostId),
        type: 'video'
      }
    })

    const media = result?.data?.cloudinaryGetAssetById
    if (media)
      return {
        hostId,
        host: MediaHostEnum.Cloudinary,
        type: MediaTypeEnum.Audio,
        media
      }

    return null
  } catch (error) {
    return {
      media: {
        error
      }
    }
  }
}

/**
 * Validate url against Vimeo
 * Attempt to parse hostId from url
 * Determine if the media exists in Vimeo
 * @param {String} url
 */
export const validateVimeoUrl = async (url: string) => {
  try {
    const hostId = url.match(MEDIA_REGEX_MAP.vimeoHostId)?.[1]

    if (!hostId) return null

    const video = await getVideoDetails(hostId)
    if (video)
      return {
        hostId,
        host: MediaHostEnum.Vimeo,
        type: MediaTypeEnum.Video,
        media: video
      }

    return null
  } catch {
    return null
  }
}

/**
 * Converts from a Cloudinary upload response into a hostMedia structure
 */
function hostMediaFromCloudinaryResponse(audio) {
  const values = {
    duration: audio.duration,
    width: audio.width,
    height: audio.height,
    version: audio.version,
    size: audio.size
  }

  return {
    ...values,
    hostId: audio.public_id,
    host: 'cloudinary',
    name: audio.original_filename || audio.filename,
    type: 'audio',
    status: 'complete',
    transcode: 'complete',
    fileType: audio.format,
    url: audio.secure_url
  }
}

/**
 * Converts from a Vimeo upload response into a hostMedia structure
 */
function hostMediaFromVimeoResponse(video) {
  const values = {
    duration: video.duration,
    width: video.width,
    height: video.height,
    name: video.name
  }

  return {
    ...values,
    hostId: video.uri.split('/').pop(),
    host: 'vimeo',
    type: 'video',
    status: VIMEO_TRANSCODE_STATUS_MAP[video.upload?.status],
    url: video?.files?.[0]?.link,
    transcode: VIMEO_TRANSCODE_STATUS_MAP[video.transcode?.status] || 'error'
  }
}
