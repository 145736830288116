import { EditorState } from 'draft-js'
import { FormikProps } from 'formik'
import React from 'react'
import { Image, View } from 'react-native'
import { Divider } from 'react-native-elements'
import Dropdown from '../../../../components/common/Dropdown'
import ContentDrop from '../../../../components/common/form/ContentDrop'
import { Flex } from '../../../../components/FlexBox'
import useToast, { ToastType } from '../../../../hooks/useToast'
import useTranslation from '../../../../hooks/useTranslation'
import DropImageIcon from '../../../../images/drop-image.svg'
import idToCloudinaryUrl from '../../../../utils/idToCloudinaryUrl'
import {
  ColumnStateType,
  IBlockColumnState,
  IEditorState,
  ISectionState,
  ProgramGuestState
} from '../../types'
import { getEditorStateFromHtml } from '../../utils'
import AddProgramGuest, { IQueryResponse } from './AddProgramGuest'
import { BlocksController } from './BlocksController'
import styled, { useTheme } from 'styled-components/native'
import { ErrorText } from '../../Common'
import { SummitRichTextEditor } from '../../editors/SummitRichTextEditor'
import SummitTextInput from '../../editors/SummitTextInput'
import { ProfilePictureType } from './queries/profilePictureFragment'
import { ImageType } from './queries/imageFragment'
import { isRawStateEmpty } from '../../validation'

interface IProps {
  form: FormikProps<IEditorState>
  section: ISectionState
  sectionIndex: number
}

const Container = styled(View)`
  border-width: 1px;
  border-radius: 3px;
  border-color: ${props => props.theme.colors.border};
  padding: 16px;
`

const resolvePersonPhotoId = (
  person: IQueryResponse['person']
): { fileName: string; cloudinaryId: string; cropping: string } => {
  if (person.profilePicture) {
    if ((person.profilePicture as any).__typename === 'ProfilePicture') {
      return {
        cloudinaryId: (person.profilePicture as ProfilePictureType).image
          .cloudinaryId,
        fileName: '',
        cropping: (person.profilePicture as ProfilePictureType).cropping
      }
    }
    return {
      cloudinaryId: (person.profilePicture as ImageType).cloudinaryId,
      fileName: (person.profilePicture as ImageType).filename,
      cropping: ''
    }
  }
  return { fileName: '', cloudinaryId: '', cropping: '' }
}

const ProgramGuests = ({ section, form, sectionIndex }: IProps) => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const { showToast } = useToast()

  return (
    <Container>
      {section.blocks.map((block, blockIndex) => {
        const type = block.type

        if (type === 'pageBreak') {
          const column: IBlockColumnState = block.columns[0] || {
            type: 'custom',
            subType: 'page-break'
          }
          const state = (column.state as ProgramGuestState) || {}
          return (
            <React.Fragment key={state?.id}>
              <BlocksController
                block={block}
                blockIndex={blockIndex}
                section={section}
                sectionIndex={sectionIndex}
                form={form}
                showLiveInApp={false}
              />
              <Divider style={{ marginBottom: 6 }} />
            </React.Fragment>
          )
        } else {
          const touched = !!form.touched?.sections?.[sectionIndex]?.blocks?.[
            blockIndex
          ]
          const error =
            touched &&
            form.errors[`sections.${sectionIndex}.blocks.${blockIndex}`]
          const column: IBlockColumnState = block.columns[0] || {
            type: 'custom',
            subType: 'program-guest'
          }
          const state = (column.state as ProgramGuestState) || {}
          const pictureUrl = state.photo?.cloudinaryId
            ? idToCloudinaryUrl(state.photo.cloudinaryId)
            : null

          const isBioEmpty = isRawStateEmpty(state.bio)

          return (
            <React.Fragment key={block.uid}>
              <BlocksController
                block={block}
                blockIndex={blockIndex}
                section={section}
                sectionIndex={sectionIndex}
                form={form}
              />
              <Flex flexDirection="row" style={{ marginBottom: 8 }}>
                <div style={{ padding: 6 }}>
                  <ContentDrop
                    value={
                      pictureUrl
                        ? {
                            fileName: state.photo.fileName,
                            url: pictureUrl,
                            publicId: state.photo.cloudinaryId
                          }
                        : null
                    }
                    setValue={value => {
                      form.setFieldValue(
                        `sections.${sectionIndex}.blocks.${blockIndex}.columns.${0}.state.photo`,
                        value
                          ? {
                              fileName: value!.fileName,
                              cloudinaryId: value!.publicId
                            }
                          : null
                      )
                    }}
                    acceptFormat={'image/*'}
                    dropZoneContent={{
                      imageIcon: <DropImageIcon />,
                      title: t('media:dropImage')
                    }}
                    removeContentModal={{
                      title: t('media:removeImage'),
                      description: t('media:removeImageConfirmation')
                    }}
                    onRenderUrl={uri => (
                      <Image
                        style={{ height: 200, width: 100, alignSelf: 'center' }}
                        resizeMode="contain"
                        source={{ uri }}
                      />
                    )}
                    hasError={error && !pictureUrl}
                    setError={error => {
                      showToast(error, ToastType.ERROR)
                      form.setFieldValue(
                        `sections.${sectionIndex}.blocks.${blockIndex}.columns.${0}.state.photo`,
                        null
                      )
                    }}
                    cloudinaryDir="summitSites"
                    style={{
                      padding: '12px 64px',
                      width: 200
                    }}
                  />
                  <ErrorText
                    error={error && !pictureUrl && 'No Image Uploaded!'}
                  />
                </div>
                <Flex flexDirection="column" style={{ padding: 6, width: 522 }}>
                  <SummitTextInput
                    placeholder={t('summits:editor:programGuests:title')}
                    value={state?.title}
                    onChangeText={x =>
                      form.setFieldValue(
                        `sections.${sectionIndex}.blocks.${blockIndex}.columns.${0}.state`,
                        { ...column.state, title: x }
                      )
                    }
                    style={{
                      minWidth: 512,
                      marginRight: 10,
                      marginBottom: 10
                    }}
                  />
                  <SummitTextInput
                    placeholder={t('summits:editor:programGuests:firstName')}
                    value={state?.name.first}
                    onChangeText={x =>
                      form.setFieldValue(
                        `sections.${sectionIndex}.blocks.${blockIndex}.columns.${0}.state`,
                        {
                          ...column.state,
                          name: { ...(column.state as any).name, first: x }
                        }
                      )
                    }
                    style={{
                      minWidth: 512,
                      marginRight: 10,
                      marginBottom: 10
                    }}
                  />
                  <SummitTextInput
                    placeholder={t('summits:editor:programGuests:lastName')}
                    value={state?.name.last}
                    onChangeText={x =>
                      form.setFieldValue(
                        `sections.${sectionIndex}.blocks.${blockIndex}.columns.${0}.state`,
                        {
                          ...column.state,
                          name: { ...(column.state as any).name, last: x }
                        }
                      )
                    }
                    style={{
                      minWidth: 512,
                      marginRight: 10,
                      marginBottom: 10
                    }}
                  />
                  <SummitRichTextEditor
                    onChange={x => {
                      form.setFieldValue(
                        `sections.${sectionIndex}.blocks.${blockIndex}.columns.${0}.state`,
                        { ...column.state, bio: x }
                      )
                    }}
                    onBlur={() => {
                      form.setFieldTouched(
                        `sections.${sectionIndex}.blocks.${blockIndex}`
                      )
                    }}
                    editorState={state.bio as EditorState}
                    placeholder={t('summits:editor:programGuests:bio')}
                    style={{
                      marginRight: 20,
                      height: '250px',
                      width: '100%',
                      borderColor:
                        error && isBioEmpty ? colors.danger : colors.border
                    }}
                  />
                  <ErrorText error={isBioEmpty && error} />
                </Flex>
                <div style={{ padding: 6 }}>
                  <Dropdown
                    options={[
                      { label: 'Speaker', value: 'speaker' },
                      { label: 'Moderator', value: 'moderator' },
                      { label: 'Special Guest', value: 'specialGuest' }
                    ]}
                    onSelect={v =>
                      form.setFieldValue(
                        `sections.${sectionIndex}.blocks.${blockIndex}.columns.${0}.state`,
                        { ...column.state, guestType: v }
                      )
                    }
                    value={state.guestType}
                    placeholder={t('summits:editor:programGuests:type')}
                  />
                </div>
              </Flex>
              {blockIndex !== section.blocks.length - 1 && (
                <Divider style={{ marginBottom: 6 }} />
              )}
            </React.Fragment>
          )
        }
      })}
      <Flex flexDirection="row" alignItems="end">
        <AddProgramGuest
          addPageBreak={() => {
            const speakersSection = form.values.sections.find(
              v => v.uid === 'program-guests'
            )!
            speakersSection.blocks.push({
              uid: 'pageBreak',
              type: 'pageBreak',
              live: false,
              exportToPdf: true,
              columns: [
                {
                  type: ColumnStateType.Custom,
                  subType: 'pageBreak'
                }
              ]
            })
            form.setValues(form.values)
          }}
          onSelected={person => {
            const speakersSection = form.values.sections.find(
              v => v.uid === 'program-guests'
            )!
            speakersSection.blocks.push({
              type: 'program-guest',
              live: true,
              exportToPdf: false,
              columns: [
                {
                  type: ColumnStateType.Custom,
                  subType: 'program-guest',
                  state: {
                    id: person.id,
                    name: {
                      first: person.firstName,
                      last: person.lastName
                    },

                    photo: resolvePersonPhotoId(person),
                    salesforceId: person.salesforceId,
                    bio: getEditorStateFromHtml(person.bio ?? ''),
                    title: person.jobTitle,
                    guestType: 'speaker'
                  }
                }
              ]
            })
            form.setValues(form.values)
          }}
        />
      </Flex>
    </Container>
  )
}

export default ProgramGuests
