import React, { useState, useContext, useEffect } from 'react'
import {
  View,
  Text,
  ViewStyle,
  TouchableOpacity,
  ActivityIndicator
} from 'react-native'
import styled, { ThemeContext, useTheme } from 'styled-components/native'
import { H4 } from '../../../components/common/Text'
import FileDropzone, {
  DZHeaderText,
  DZSubText,
  DropZoneContentProps,
  DZIconContainer
} from '../../../components/FileDropzone'
import Button from '../../../ui-library/Button'
import TextInput from '../../../ui-library/TextInput'
import useTranslation from '../../../hooks/useTranslation'
import useToast, { ToastType } from '../../../hooks/useToast'
import CopyToClipboardButton from './CopyToClipboardButton'
import Modal from '../../../components/common/Modal'
import useMediaUploader from './useMediaUploader'
import DropMediaIcon from '../../../images/drop-media.svg'

import {
  ACCEPTED_MEDIA_VIDEO_FORMATS,
  ACCEPTED_MEDIA_AUDIO_FORMATS,
  MEDIA_REGEX_MAP
} from '../constants/media'

export interface MediaUploaderValue {
  id: string
  hostId: string
  status: string
  transcode: string
  name: string
  url: string
  type: string
  host: string
}

export interface MediaUploaderProps {
  value?: MediaUploaderValue | null
  hasError?: boolean
  setValue: (value: Partial<MediaUploaderValue> | null) => void
  setError: (value: string | undefined) => void
  setUploading: (value: boolean) => void
  style?: ViewStyle
  documentFileSizeLimit?: number
  audioFileSizeLimit?: number
  isOnlyUrlUpload?: boolean
}

interface MediaUploadPreviewProps {
  progress?: Number | null
  value: any
}

export default function MediaUploader({
  value,
  hasError,
  setValue,
  setError,
  setUploading,
  style,
  documentFileSizeLimit,
  audioFileSizeLimit,
  isOnlyUrlUpload = false
}: MediaUploaderProps) {
  const { t } = useTranslation()
  const { showToast } = useToast()
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [_progress, setProgress] = useState<Number | null>(null)

  const {
    uploadMedia,
    progress,
    error,
    validateUploadedMediaUrl
  } = useMediaUploader(
    setValue,
    documentFileSizeLimit,
    audioFileSizeLimit,
    [isUploading, setIsUploading],
    [_progress, setProgress]
  )

  const withMediaUploadValidator = props => {
    return (
      <DropZoneContent
        {...props}
        isOnlyUrlUpload={isOnlyUrlUpload}
        validator={validateUploadedMediaUrl}
      />
    )
  }

  useEffect(() => {
    setUploading(isUploading)
  }, [isUploading])

  useEffect(() => {
    if (error === null) return

    if (error === 'createHostMediaFailed') {
      showToast(t('media:hostMediaSaveFail'), ToastType.ERROR)
    } else if (error === 'noFileSelected') {
      showToast(t('media:uploadFail'), ToastType.ERROR)
    } else {
      if (typeof error === 'string') {
        showToast(error, ToastType.ERROR)
        setError(error)
      }
    }
  }, [error])

  const showDropzone = !isUploading && !value

  if (!audioFileSizeLimit || !documentFileSizeLimit) {
    return null
  }

  return showDropzone ? (
    <FileDropzone
      hasError={hasError}
      accept={[
        ...ACCEPTED_MEDIA_VIDEO_FORMATS,
        ...ACCEPTED_MEDIA_AUDIO_FORMATS
      ].join(',')}
      upload={uploadMedia}
      style={style}
      Content={withMediaUploadValidator}
    />
  ) : (
    <MediaContainer style={style} hasError={hasError}>
      <MediaUploadPreview progress={progress} value={value} />
      <BottomRow>
        {value && value.url && <CopyToClipboardButton url={value.url} />}
        {value && value.name && (
          <Filename title={value.name}>{value.name}</Filename>
        )}
        {value && (
          <ClearButton onPress={() => setShowConfirmationModal(true)}>
            <ClearButtonText>×</ClearButtonText>
          </ClearButton>
        )}
      </BottomRow>

      <Modal
        open={showConfirmationModal}
        close={() => setShowConfirmationModal(false)}
      >
        <H4>{t('media:removeMedia')}</H4>
        <View style={{ padding: 10 }}>
          <View style={{ paddingBottom: 10 }}>
            <Text>{t('media:removeMediaConfirmation')}</Text>
          </View>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end'
          }}
        >
          <Button
            title={t('common:labels:cancel')}
            style={{ marginRight: 10 }}
            type="outline"
            onPress={() => {
              setShowConfirmationModal(false)
            }}
          />
          <Button
            title={t('common:labels:remove')}
            onPress={() => {
              setValue(null)
              setError(undefined)
              setUploading(false)
              setIsUploading(false)
              setProgress(null)
              setShowConfirmationModal(false)
            }}
          />
        </View>
      </Modal>
    </MediaContainer>
  )
}
const MediaContainer = styled(View)`
  border-radius: 3px;
  ${({ theme, hasError }) =>
    hasError
      ? `
      border: 1px solid ${theme.colors.danger};
    `
      : ``}
`
const BottomRow = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
`
const Filename = styled(Text)`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  color: gray;
  font-style: italic;
  white-space: nowrap;
  padding: 0 8px;
`
const ClearButton = styled(TouchableOpacity)`
  background: rgba(0, 0, 0, 0.24);
  width: 1.2em;
  height: 1.2em;
  border-radius: 2em;
`
const ClearButtonText = styled(Text)`
  text-align: center;
  color: #fff;
  font-size: 18px;
  line-height: 1em;
  font-weight: bold;
`

const ErrorText = styled(Text)`
  ${({ theme }) => `
    color: ${theme.colors.inputTextError};
  `}
`

const DropZoneContent = ({
  selectFileButton,
  validator,
  isOnlyUrlUpload = false
}: DropZoneContentProps) => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const [showUrlModal, setShowUrlModal] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [formValue, setFormValue] = useState<string>('')
  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  return (
    <>
      {!isOnlyUrlUpload ? (
        <>
          <DZIconContainer>
            <DropMediaIcon />
          </DZIconContainer>
          <DZHeaderText>{t('media:dropMedia')}</DZHeaderText>
          {selectFileButton}
          <Text> {t('fileDropZone:or')} </Text>
        </>
      ) : null}

      <DZSubText>
        <Button
          title={t('media:pasteURL')}
          type="clear"
          onPress={() => setShowUrlModal(true)}
          titleStyle={{
            color: colors.brightPrimary
          }}
        />
      </DZSubText>
      <Modal
        open={showUrlModal}
        close={() => {
          setError(null)
          setFormValue('')
          setShowUrlModal(false)
        }}
      >
        <H4>{t('media:existingMediaUrl')}</H4>
        <TextInput
          placeholder={t('forms:mediaUrl')}
          value={formValue}
          onChangeText={value => {
            // Front end regex validation
            const validUrl = MEDIA_REGEX_MAP.mediaUrl.test(value)
            if (!validUrl && !error) {
              setError(t('media:invalidMediaUrl'))
            } else if (validUrl && error) {
              setError('')
            }
            setFormValue(value)
          }}
          hasError={!!error}
          autoFocus
          style={{ minWidth: '648px', marginTop: '10px' }}
        />
        <ErrorText>{error}</ErrorText>
        <Button
          title={t('common:labels:submit')}
          containerStyle={{ alignItems: 'flex-end' }}
          type="solid"
          isProcessing={isProcessing}
          onPress={async () => {
            // Validate with host onPress
            if (error) setError(null)
            setIsProcessing(true)
            if (validator) {
              const result: any = await validator(formValue, true)
              if (!result?.[0]) {
                setIsProcessing(false)
                setError(
                  t(
                    result?.[1]?.message?.includes('Resource not found')
                      ? 'media:resourceNotFound'
                      : 'media:invalidMediaUrl'
                  )
                )
              }
            }
          }}
        />
      </Modal>
    </>
  )
}

function MediaUploadPreview({ progress, value }: MediaUploadPreviewProps) {
  const { t } = useTranslation()
  const theme = useContext(ThemeContext) as any
  const transcode = value?.transcode
  const url = value?.url
  const type = value?.type
  const status = value?.status || 'processing'

  const showSpinner = status === 'uploading' || transcode === 'processing'
  const showProgress = progress !== null

  return (
    <PreviewContainer>
      {transcode === 'complete' &&
        (type === 'video' ? (
          <video controls height={194}>
            <source src={url} />
          </video>
        ) : (
          <audio controls>
            <source src={url} />
          </audio>
        ))}

      {transcode !== 'complete' && (
        <>
          {showSpinner && (
            <ActivityIndicator
              size="large"
              color={theme.colors.primary}
              style={{ marginBottom: 10 }}
            />
          )}
          <H4>{t(`media:statuses:${transcode || status}`)}</H4>
          {/* @ts-ignore progress is not null here */}
          {showProgress && <H4 styles={{ marginLeft: 10 }}>{progress}%</H4>}
        </>
      )}
    </PreviewContainer>
  )
}

const PreviewContainer = styled(View)`
  width: 100%;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
